import React from 'react';
import imageItsFine from './images/jpg/its_fine.jpg'
import imageNoItsFine from './images/jpg/not_fine.jpg'
import imageLoading from './images/jpg/loading.jpg'
import './App.css';
// import TimerForHBD from './TimerForHBD';

function App() {

  // const finalDate = new Date('2022-12-23T18:00:00')
  // const [mode, setMode] = useState("init")
  const modeImage = {
    init: imageLoading,
    catch_up: imageItsFine,
    no_catch_up: imageNoItsFine
  }

  return (
    <div className="App">
      <header className="App-header">
        <img src={modeImage["catch_up"]} className="photo" title={"Людина"} alt="Святослав" />
        <span style={{margin: "5px"}} >Побачимося у 2023 року!</span>
        {/*{((mode==="init")||(mode==="catch_up"))&&<span style={{margin: "5px"}}>Це запрошення на мое день народження!</span>}*/}
        {/*<TimerForHBD style={{marginTop: "5px"}} finalDate={finalDate} mode={mode} setMode={setMode} />*/}
        {/*/!*<div>*!/*/}
        {/*/!*  <span>До урочистості залишилося:</span>*!/*/}
        {/*/!*  {getTimerString(Math.abs(new Date() - finalDate))}*!/*/}
        {/*/!*  <span className={new Date() < finalDate?"Span-catch-up":"Span-no-catch-up"}>{new Date() < finalDate?"Ви ще встигаете!":"Ви всже запізднились!"}</span>*!/*/}
        {/*/!*</div>*!/*/}
        {/*<div style={{display: "flex", flexDirection: "column"}}>*/}
        {/*  <span  style={{marginTop: "5px"}}>Місце проведення: Козацька Броварня</span>*/}
        {/*  <a href="https://goo.gl/maps/KqKhxdgpTu1pumje9" target="_blank" rel="noreferrer noopener">*/}
        {/*    <span>Соборна вулиця, 16/9</span>*/}
        {/*  </a>*/}
        {/*</div>*/}
      </header>
    </div>
  );
}

export default App;
